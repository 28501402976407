<template>
  <div class="wrapper">
    <div class="banner">
      <img src="../../assets/img/banner.png" alt="" />
    </div>
    <!-- <div class="breadcrumb_box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/digitalService' }"
          >扩容提质服务</el-breadcrumb-item
        >
        <el-breadcrumb-item>一号密巢</el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->

    <div class="container">
      <div class="box">
        <div class="img_box">
          <img src="../../assets/img/detail/yhmc.png" alt="" />
        </div>
        <p>
          一号密巢云凭证夹是一款帮助中小微企业进行凭证归集、云存储和档案管理的
          全流程凭证应用，是企业客户入网即享的云空间与云服务。
          云凭证夹满足并实现财政部等九部委最新文件明确规定的九种数字凭证的归集，
          并可对接市面多种记账软件，实现数字凭证自动流转，有效简化记账流程，
          降低记账成本。支持代账会计一人多户服务模式，同时实现原始凭证归档管理与
          数据提取记账隔离，保护企业客户凭证权益。
          用户只需用一个手机号进行授权即可享受一站式凭证数字化云服务，
          可通过PC端，手机小程序及H5网页等多种渠道进行登录，随时随地进行凭证查看及处理。
          此外，一号密巢凭证夹聚合数电通凭证发展委员会中小微企业服务生态平台能力，
          还提供如记账报税、审计金融、信用估值等更多延伸服务，
          满足中小微企业生产经营全流程需求，更好地帮助企业实现降本增效，
          为企业发展保驾护航。
        </p>
      </div>
    </div>

    <div class="btn" @click="subInfo">立即体验</div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    subInfo() {
      this.$router.push({
        path: "/info",
        query: { org: "sz" },
      });
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
.wrapper {
}

.banner {
  width: 100%;
  height: 300px;
}

.banner img {
  display: block;
  width: 100%;
  height: 100%;
}

.breadcrumb_box {
  width: 1200px;
  padding: 30px 0 0;
  margin: 0 auto;
}

.box {
  width: 1200px;
  padding: 30px 0;
  margin: 0 auto;
}

.img_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-bottom: 80px; */
}

.img_box span {
  font-size: 18px;
  font-weight: 600;
  color: #333333;
}

.img_box img {
  display: block;
  width: 600px;
  height: 530px;
}

.simg_box {
  display: flex;
  justify-content: center;
}

.img_box1 {
  width: 50%;
}

.img_box1 img {
  width: 200px;
  height: 350px;
}

.box p {
  font-size: 18px;
  color: #333333;
  line-height: 34px;
  padding: 20px 0;
  text-align: justify;
}

.box p:first-child {
  padding: 0;
}

.box p span {
  display: block;
  font-weight: bold;
  margin: 10px 0;
}

.btn {
  width: 130px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 25px;
  background: #055afe;
  color: #fff;
  margin: 20px auto;
  cursor: pointer;
}

table tr th {
  text-align: center;
  padding: 5px;
  font-size: 14px;
}

table tr td {
  text-align: center;
  padding: 10px;
}
</style>
